import {useLoginModalStore} from "@stores/login";
import {mapActions, mapState} from 'pinia'

export default {
  computed: {
    ...mapState(useLoginModalStore, ['showModal'])
  },
  methods: {
    ...mapActions(useLoginModalStore, ['toggleLoginModal','showLoginModal','hideLoginModal','requestedToLogin'])
  }
}